body {
  background-color: #0d5869;
}

.centered-box {
  width: 360px;
  margin: 0 auto;
  margin-top: 8%;
}

.box-container {
  padding: 24px;
  padding-bottom: 40px;
  background-color: #fff;
}

.box-buttons-wrap {
  margin-top: 25px;
}

.forgot-your-password {
  color: #0f4952;
  font-size: 13px;
  line-height: 14px;
  float: right;
  padding-right: 3px;
  padding-top: 5px;
  cursor: pointer;
  &:hover {
    color: #000000;
  }
}

.MuiFormControl-root.mb-10,
.mb-10 {
  margin-bottom: 10px;
}

.MuiFormControl-root.mt-10,
.mt-10 {
  margin-top: 10px;
}

.MuiFormControl-root.mb-20,
.mb-20 {
  margin-bottom: 20px;
}

.MuiFormControl-root.mb-5,
.mb-5 {
  margin-bottom: 5px;
}

.MuiFormControl-root.mb-30,
.mb-30 {
  margin-bottom: 30px;
}

.box-title {
  color: #0f4952;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  min-height: 48px;
  button {
    margin-right: 5px;
    top: 1px;
  }
}

.helper-text {
  color: #878787;
  font-size: 13px;
  line-height: 18px;
}

.success-panel {
  text-align: center;
  svg {
    font-size: 5rem;
    color: #8aba04;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.footer-info {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  img {
    transition: all ease-in 100ms;
    height: 55px;
    &:hover {
      height: 75px;
    }
  }
}

.check-wrap {
  margin-bottom: 10px;
  .check-row {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
      color: #a8a8a8;
      font-size: 18px;
    }
    .check-text {
      font-size: 12px;
      color: #a8a8a8;
    }
    &.valid {
      svg {
        color: #8aba04;
      }
      .check-text {
        color: #8aba04;
        font-weight: 500;
      }
    }
  }
}

.footer-info {
  a.google-badge {
    background-image: url("assets/img/google-play-badge_60.png");
    height: 60px;
    width: 155px;
    background-repeat: no-repeat;
    transition: all ease-in 100ms;
    span {
      display: none;
    }
    &:hover {
      background-image: url("assets/img/google-play-badge_75.png");
      width: 194px;
      height: 75px;
    }
  }
}

.password-textfield {
  .MuiInputBase-input {
    padding-right: 40px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
  .MuiInputAdornment-root {
    position: absolute;
    right: 14px;
    margin-right: 0px;
  }
}
