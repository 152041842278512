.logo {
    display: flex;    
    justify-content: center;
    margin-bottom: 22px;
    .logo-content {
        display: flex;
        align-items: center;
    }
    .logo-img {
        display: flex;
        margin-right: 10px;
        img {
            width: 30px;
            height: 30px;
        }
    }
    .logo-text {
        img {
            width: 115px;
        }        
    }
}