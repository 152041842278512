.MuiInputAdornment-root {
    .sm-textfield-icon {
      padding: 4px !important;
      margin-right: -7px !important;
      display: none;
      svg {
        font-size: 1.25rem;
      }
    }
}

.sm-outlined-input-small {
    &.show-icon-always {
        .sm-textfield-icon {
            display: inline-flex;
        }
    }
    &.show-icon-on-hover {
        .sm-textfield-icon {
            display: none;
        }
    }
    &.show-icon-on-hover:hover {
        .sm-textfield-icon {
            display: inline-flex;
        }
    }
}

input.sm-outlined-input-small,
.sm-outlined-input-small {
  .MuiOutlinedInput-inputAdornedEnd {
    padding-right: 0;
  }
  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 0;
  }
  &.no-right-pad {
    input {
      padding-right: 0px;
    }
  }
  input {
    padding: 10.5px 14px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
    font-size: 0.9rem;
  }
}